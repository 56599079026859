import React from "react";
import { availableSpinsAtom, dripPointsAtom, useAtom } from "~/atoms";

export function useUserStats(username: string | undefined) {
  const [points, setPoints] = useAtom(dripPointsAtom);
  const [availableSpins, setAvailableSpins] = useAtom(availableSpinsAtom);

  const fetchUserStats = React.useCallback(async () => {
    const response = await fetch(`/api/user-stats?username=${username}`);
    if (response.ok) {
      const data = await response.json();
      setPoints(data.points);
      setAvailableSpins(data.availableSpins);
    }
  }, [username, setPoints]);

  React.useEffect(() => {
    if (!username) return;
    fetchUserStats();
  }, [username, fetchUserStats]);

  return {
    userStats: {
      points,
      availableSpins,
    },
    fetchUserStats,
  };
}
